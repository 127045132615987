import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { loadingIcon } from "../loadingIcon";

import { t } from "../services";
import { store } from "../store";
import { push } from "../redux/BrowserNavigation";
import { ImageViewer } from './ImageViewer';

import "bootstrap/dist/css/bootstrap.min.css";
import "./card-style.css";

const ButtonToForm = props => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <button
        onClick={e => {
          let page = store.getState().page;
          store.dispatch(
            push(getFormViewPath(page.url, props.entity, props.id))
          );
        }}
        className="btn btn-outline-success"
      >
        <Icon>edit</Icon>
        {t`Open`}
      </button>
      <button
        onClick={props.delete}
        className="btn btn-outline-danger"
      >
      <FontAwesomeIcon icon={faTrash} style={{fontSize:'26.5px'}} />
      </button>
    </>
  );
};
const ButtonToTrash = props => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <button
        onClick={() => {
          let page = store.getState().page;
          store.dispatch(
            push(
               getFormViewPath(page.url, props.entity, props.id)
            )
          );
        }}
        className="btn btn-outline-primary"
      >
        <FontAwesomeIcon icon={faEye} style={{fontSize:'26.5px'}} />
      </button>

      <button
        onClick={props.restore}
        className="btn btn-outline-success"
      >
        <Icon>restore</Icon>
      </button>

      <button
        onClick={props.purge}
        className="btn btn-outline-danger"
      >
        <Icon>delete_forever</Icon>
      </button>
    </>
  );
};

// Card takes an avatar image {avatarId, x, y, scale, rotate, url}
// or a URL
export function Card(props) {
  const [id, setId] = useState(props.id);
  const [image, setImage] = useState(undefined);

	if (!image || props.id !== id) {
		props.image.then(image=>{
      setImage(image);
      setId(props.id);
    });
		return loadingIcon;
	}
  return (
    <>
      <div className="card text-center">
        {(image.url)?
        <ImageViewer image={image} />:
        <div className="overflow">
          <img src={image.src} className="card-img-top" style={{height: '160px'}}/>
        </div>
        }
        <div className="card-body text dark">
          <h6 className="card-title">{props.title}</h6>
          <p className="card-text text-secondary">{props.description}</p>
          {props.entity.endsWith("-trash") ? (
            <ButtonToTrash id={props.id} entity={props.entity} purge={props.purge} restore={props.restore} />
          ) : (
            <ButtonToForm id={props.id} entity={props.entity} delete={props.delete}/>
          )}
        </div>
      </div>
    </>
  );
}
