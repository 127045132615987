import React from "react";
import { getServiceUri } from "../../../../auto/js/metadata";
import { rest, t } from "../../../../auto/js/services";
import { AFFILIATION_MTLB_ORDER_BY_NAME } from "../../../../auto/js/metadata/AffiliationMtlbOrderBy";
import { MTLB_STATUS_ARCHIVED, MTLB_STATUS_READY_FOR_APPROVAL } from "../../../../auto/js/metadata/MtlbStatus";

export const affiliationApplicationListColumns = [
    {title: "id", field: "id"},
    {title: "name", field: "name"},
    {title: "appointmentDate", field: "appointmentDate"},
    {title: "appointmentTime", field: "appointmentTime"},
];

export const getData = async (filter) => {
    return await rest.request(getServiceUri() + "affiliation-mtlb/search-document/", "POST", filter);
}

export const countData = async (filter) => {
    return await rest.request(getServiceUri() + "affiliation-mtlb/count-document/", "POST", filter);
}

export const getOrderBy = () => {
    return AFFILIATION_MTLB_ORDER_BY_NAME
}

export const getMtlbTypeList = () => {
    return []
}

export const filterData = (DefaultRows) => {
    const newRows = [];
    for (let i in DefaultRows) {
        let row = DefaultRows[i];
        let date = row.appointmentDate
        if ( date !== null)
            row.appointmentDate = date[2] + '-' + date[1] + '-' + date[0];
        let time = row.appointmentTime
        if (time !== null) {
            let h = time[0] < 10 ? '0' + time[0] : time[0];
            let m = time[1] < 10 ? '0' + time[1] : time[1];
            row.appointmentTime = h + ":" + m;
        }
      newRows.push(row);
    }
    return newRows;
}

export const getErrorList = () => {
    let list = [];
    return list;
}
