import React from "react";
import _ from 'lodash';
import { buildEmptyObject } from "../../../../auto/js/widgets";

export const affiliationApplicationFields = [
	{name: "id", type:"number", x:1, y:1, layout:"col-md-6"},
    {name:"name", type:"text", x:2, y:1, layout:"col-md-6"},
    {name: "appointmentDate", type: "date", x:1, y:2, layout:"col-md-6"},
    {name: "appointmentTime", type: "time", x:2, y:2, layout:"col-md-6"}

];

export const buildAffiliationApplicationFormEmptyObject  = () => {
    const empty = buildEmptyObject(affiliationApplicationFields);
	return empty;
}

export const form2dto = (formData, dto) => {
	if(formData.appointmentDate != null && typeof(formData.appointmentDate) != 'string') {
		let appointmentDateAsDate = new Date(formData.appointmentDate);
		dto.appointmentDate = appointmentDateAsDate.getFullYear() + "-" + ('0' + (appointmentDateAsDate.getMonth() + 1)).slice(-2) + "-" + ('0' + appointmentDateAsDate.getDate()).slice(-2);
	}
    if (formData.appointmentTime != null && typeof(formData.appointmentTime) != 'string') {
		const date = new Date(formData.appointmentTime)
		let birthHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
		let birthMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
		dto.appointmentTime = birthHour + ":" + birthMinute;
	}
}
export const dto2form = (dto) => {
    if (dto.appointmentTime !== null) {
        dto.appointmentTime = new Date().setHours(dto.appointmentTime[0], dto.appointmentTime[1]);
      }
	return dto;
}