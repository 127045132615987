import React from 'react';
import Button from '@material-ui/core/Button';
import SearchBar from "material-ui-search-bar";
import { t } from '../services';

export function SearchComponent(props) {

    return (
     <div className='search-component'>
     <SearchBar
      onChange={props.onChange}
      onRequestSearch={props.onRequestSearch}/>            
		<div className="advanced-search-button">
                <Button
                    variant="outlined" 
                    color="primary"
                    onClick={props.onAdvancedSearch}
                    >
                    {t`Advanced Search`}
                </Button>
            </div>
        </div>
    )
} 


