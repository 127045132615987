import {whoami} from '../../../auto/js/users/UserInfo';
import { RIBBON_MENU } from '../../../auto/js/metadata/MenuType';
import { displayAffiliationApplicationList } from '../../../auto/js/lists/affiliationApplication/AffiliationApplicationList';
import { displayTranslationsList } from '../lists/metadata/TranslationsList';
import { displayNewTranslationsForm } from '../../../auto/js/forms/metadata/TranslationsForm';

export const menu = () => {
    return {
        ...((whoami().roles.includes('ALL')|| whoami().roles.includes('REGISTRAR')) && {
            "affiliations": {
				submenu: {
					"affiliations-application": {
						options: {
							search: { label: "Applications", do: () => displayAffiliationApplicationList() },
						},
						label: "Affiliation Applications"
					}
				},
				label: "Affiliations"
			},
			"configuration": {
			   submenu: {
				   "translations": {
					   options: {
						   list: {label: "Translations", do: () => displayTranslationsList()},
						   new: {label: "New Translation", do: () => displayNewTranslationsForm()},
					   },
					   label: "Translations"
				   },
			   },
			   label: "Configuration",
		   }
        })
        }
}
export const menuType = RIBBON_MENU;
