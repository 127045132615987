import React from "react";
import {v4 as uuidv4} from 'uuid';

import { createFormComponent } from '../../widgets/FormComponent';
import { OPEN_VIEW_EVENT } from '../../events/Gui';
import { rest } from "../../services/RestClient";
import { t } from "../../services/i18ndb";
import { showNotification } from "../../utils";
import { getServiceUri } from "../../metadata";
import { updateAffiliationApplicationForm, loadAffiliationApplicationFormData } from "./AffiliationApplicationApi";
import { affiliationApplicationFields } from "../../../../main/js/forms/affiliationApplication/AffiliationApplicationFormCommon.jsx";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AttachmentsArea } from "../../widgets/Attachments";

import { createTableComponent } from "../../widgets/TableComponent";

let AffiliationApplicationForm = createFormComponent(affiliationApplicationFields);
	
class FormComponent extends React.Component {
	constructor(props) {
		super(props);
    }

	render() {
		return (
		<>
			<img alt="approved.png" src="/public/approved.png" style={{maxWidth: '130px'}}/>
			<AffiliationApplicationForm key={this.props.key} loadData={async () => loadFormData(this.props.id)} readOnly onSubmit={() => null} id={this.props.id} buttons={() => null} />
		</>
		)
	}

}

	

export const displayReadApprovedAffiliationApplicationForm = (id) => {
	let uuid = uuidv4();
	OPEN_VIEW_EVENT.publish({
		uuid, view: () => <FormComponent key={uuid} id={id} />
	});
}

const loadFormData = async (id) => {
	return await loadAffiliationApplicationFormData(id);
}
