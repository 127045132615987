export const AFFILIATION_MTLB_ORDER_BY_ID = "ID";
export const AFFILIATION_MTLB_ORDER_BY_NAME = "NAME";
export const AFFILIATION_MTLB_ORDER_BY_SUBMISSION_DATE = "SUBMISSION_DATE";
export const AFFILIATION_MTLB_ORDER_BY_MTLB_STATUS = "MTLB_STATUS";
export const AFFILIATION_MTLB_ORDER_BY_MTLB_TYPE = "MTLB_TYPE";
export const AFFILIATION_MTLB_ORDER_BY_ISSUED_BY = "ISSUED_BY";
export const AFFILIATION_MTLB_ORDER_BY_AFFILIATION_RECORD_ID = "AFFILIATION_RECORD_ID";
export const AFFILIATION_MTLB_ORDER_BY_RECORD_ID = "RECORD_ID";
export const AFFILIATION_MTLB_ORDER_BY_APPOINTMENT_DATE = "APPOINTMENT_DATE";
export const AFFILIATION_MTLB_ORDER_BY_APPOINTMENT_TIME = "APPOINTMENT_TIME";
