import React from 'react';

import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent, SidebarFooter } from 'react-pro-sidebar';

import './sidebar.scss';
import { i18ndb, t } from '../../../auto/js/services';
import { menu } from '../../../main/js/router/Menu';

const buildItemList = (subMenu) => {
  let items = [];
  const subMenuKeys = Object.keys(subMenu);
  subMenuKeys.forEach((key, index) => {
      let options = subMenu[key].options;
      Object.entries(options).forEach(([option, descriptor]) => {
        items.push(
        <MenuItem onClick={descriptor.do}> {i18ndb._(descriptor.label)} </MenuItem>
        )
      });
  });
  return items;
}

const buildMenu = (options) => {
  const items = []
  const optionsKeys = Object.keys(options);
  optionsKeys.forEach((key, index) => {
    if (options[key]["submenu"])
      items.push(
        <SubMenu title={i18ndb._(options[key].label)}>
         {buildItemList(options[key]["submenu"])}
        </SubMenu>
      )
    else
      items.push(
        <MenuItem onClick={options[key].do}> {i18ndb._(options[key].label)} </MenuItem>
      )
  });
	return items;
}

export const SideMenu = () => {

    return (
    <ProSidebar>
      {/* Header */}
      <SidebarHeader>
        <Menu>
            <MenuItem>
              <div
                style={{
                  padding: '9px',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                  fontSize: 15,
                  letterSpacing: '1px'
                }}
              >
                {t`Perfil gestor`}
              </div>
            </MenuItem>
        </Menu>
      </SidebarHeader>
      {/* Content */}
      <SidebarContent>
        <Menu>
          {buildMenu(menu())}
        </Menu>
      </SidebarContent>
      {/* Footer */}
      <SidebarFooter style={{ textAlign: 'center' }}>
        <div className="sidebar-btn-wrapper" style={{ padding: '16px' }}>  
         <span>{t`Opciones`}</span>
        </div>
        <img alt="logo.png" src="/public/side-logo.png"/>
      </SidebarFooter>
    </ProSidebar>
    )
}
