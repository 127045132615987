import React, { useState, useEffect } from "react";
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
export function CustomizedSnackbars(props) {
	const [display, setDisplay] = useState(false);
	const [alertLevel, setAlertLevel] = useState(props.alertLevel);
	const [alertMessage, setAlertMessage] = useState(props.alertMessage);

	useEffect(()  => {
		setDisplay(props.display)
		setAlertLevel(props.alertLevel)
		setAlertMessage(props.alertMessage)
	},[props])
  
  
	const handleClose = (event, reason) => {
	  if (reason === 'clickaway') {
		return;
	  }
	  setDisplay(false);
	};
  
	return (
	  <div >
		<Snackbar open={display} autoHideDuration={3000} onClose={handleClose}>
		  <Alert onClose={handleClose} severity={alertLevel}>
		  {alertMessage}  
		  </Alert>
		</Snackbar>
	  </div>
	);
  }