import { t } from '../services';
import { AlertDialog } from '../widgets';

export function ExitSaveDialog (props) {
    return (
        <AlertDialog
        title={t`Save your changes ?`}
        open={true}
        handleClose={props.doCancel}
        noAgree={true}
        save={props.doClose(true)}
        dontSave={props.doClose(false)}
    />
    )
}