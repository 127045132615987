import React from "react";
import _ from 'lodash';
import { getServiceUri, metadataLoader, pojoMetadata } from "../../../../auto/js/metadata";
import { rest } from "../../../../auto/js/services/RestClient";
import { t } from '../../../../auto/js/services/i18ndb';
import { showNotification } from "../../../../auto/js/utils";
import { dto2form, form2dto } from "../../../../main/js/forms/affiliationApplication/AffiliationApplicationFormCommon.jsx";

export const saveAffiliationApplicationForm = async (formData) => {
	let data = _.clone(formData);
    let dto = pojoMetadata['affiliation-mtlb'].form2dto(data);
    form2dto(formData, dto);
    try {
		return rest.request(getServiceUri() + 'affiliation-mtlb/new-document', 'POST', dto)
    } catch (err) {
        alert(err);
    }
}

export const loadAffiliationApplicationFormData = async (id) => {
	return await rest.read('affiliation-mtlb', id).then(response => {
		let form = dto2form(response);
		if (response.status)
			showNotification(response.message.split('Detail: ')[1], "error");
		return form;

	})
}

export const updatePendingAffiliationApplicationForm = async (formData) => {
	let data = _.clone(formData);
	let dto = pojoMetadata['affiliation-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'affiliation-mtlb/pending/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated affiliation-application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateAffiliationApplicationForm = async (formData) => {
	let data = _.clone(formData);
	const dto = pojoMetadata['affiliation-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.update('affiliation-mtlb', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Created affiliation-application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const deleteAffiliationApplicationForm = async (id) => {
    try {
        return rest.delete('affiliation-mtlb', id).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Deleted affiliation-application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const updateRejectedAffiliationApplicationForm = async (formData) => {
	//TODO: Move form2dto from pojo metadata
	let data = _.clone(formData);
	let dto = pojoMetadata['affiliation-mtlb'].form2dto(data);
	form2dto(formData, dto);
    try {
        return rest.request(getServiceUri() + 'affiliation-mtlb/rejected/update', 'POST', dto).then((response) =>{
			if (response.status)
				showNotification(response.message.split('Detail: ')[1], "error")
			else
				showNotification(t`Updated affiliation-application`, "success");
		});
    } catch (err) {
        alert(err);
    }
}

export const rejectAffiliationApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'affiliation-mtlb/pending/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const rejectReadyAffiliationApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'affiliation-mtlb/ready/reject', 'POST', id);
    } catch (err) {
        alert(err);
    }
}

export const approveReadyAffiliationApplicationForm = async (id) => {
    try {
        return rest.request(getServiceUri() + 'affiliation-mtlb/ready/approve', 'POST', id);
    } catch (err) {
        alert(err);
    }
}