import React from 'react';

import {v4 as uuidv4} from 'uuid';
import styled from 'styled-components';
import { Welcome } from '../../../main/js/pages/Welcome';
import { CAN_I_SWITCH, OPEN_VIEW_EVENT, OPEN_SIDE_PANEL } from '../events/Gui';

import { RibbonTab, setContextualOptions } from '../widgets';

import { ToolBar } from '../widgets/ToolBar';
import { SidePanelComponent } from '../widgets/SidePanelComponent';
import { menuType } from '../../../main/js/router/Menu';
import { RIBBON_MENU, SIDE_MENU } from '../metadata/MenuType';
import { SideMenu } from '../widgets/SideMenu';
import '@trendmicro/react-breadcrumbs/dist/react-breadcrumbs.css';
import '@trendmicro/react-buttons/dist/react-buttons.css';
import '@trendmicro/react-dropdown/dist/react-dropdown.css';
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap/dist/css/bootstrap.min.css";
import './base.css';
import '../../css/form.css'

const Main = styled.main`
    position: relative;
    overflow: hidden;
    transition: all .15s;
    height: calc(100vh - 260px);
	overflow-y: auto;
	direction: ltr;
	margin-top: 20px;
	margin-bottom: 20px;
`;

const welcomeUUID = uuidv4();

export class MainPage extends React.Component {
	constructor(props) {
		super(props);
        this.views = new Map();
		this.requestingClose = false
		let v;
		if (props.welcome != null && props.welcome)
			v = {uuid: welcomeUUID, view: props.welcome()}
		else
			v = {uuid: welcomeUUID, view: () => <Welcome key={welcomeUUID}/>};
		this.register(v);
		this.panelBody = undefined;
		this.panelTitle = "";
		this.state = {
			focusedView: v,
			
		};
		OPEN_VIEW_EVENT.subscribe((v) => 
		{
			if (!this.requestingClose)
				this.canISwitchTo(v)
		});
		OPEN_SIDE_PANEL.subscribe((panelBody, title) => {
			this.openSidePanel(panelBody, title);
		});
    }
    
    openSidePanel = (panel) => {
		this.panelBody = panel.body;
		this.panelTitle = panel.title;
		this.setState({openPanel: true});
	}

	register = (v) => {
		this.views.set(v.uuid, v);
	}
	
	unregister = (v) => {
		setContextualOptions({});
		this.views.delete(v.uuid);
	}

	canISwitchTo = async (v) => {
		if (this.state.focusedView) {
			this.requestingClose = true;
			CAN_I_SWITCH.send(this.doSwitchTo(v), () => this.doSwitchTo(v)(true));
		}
	}

	doSwitchTo = (v) => (closeCurrent) => {
		if (closeCurrent)
			this.switchView(v)
		this.requestingClose = false;
	}

	switchView = function (v) {
		// default behavior for now is one view at a time
		// this will eventually change though
		if (this.state.focusedView) {
			if (this.state.focusedView.uuid==v.uuid)
				return;
			else {
				this.unregister(this.state.focusedView);
				CAN_I_SWITCH.hangUp();
			}
		}
		if (!this.views.has(v.uuid))
			this.register(v);
		this.setState({focusedView:v, openPanel: false});
		this.requestingClose = false;
	}
	
	buildWorkspace = () => {
		let items = [];
		for (const [uuid, v] of this.views.entries()) {
			items.push(v.view());
		}
		return items;
	}
	
	render() {
		return (
			<div>
			{(menuType == SIDE_MENU )?
				<div className="row">
					<div class="col-md-2">
						<SideMenu />
					</div>
					<div className='col-md-10'>
						<ToolBar languages={this.props.languages}/>
						<Main>
						{this.buildWorkspace()}
						<SidePanelComponent open={this.state.openPanel} body={this.panelBody} title={this.panelTitle}/>
						</Main>
					</div>
				</div>	
			:(menuType == RIBBON_MENU)?
				<>
				<ToolBar languages={this.props.languages}/>
				<RibbonTab />
				<Main>
				{this.buildWorkspace()}
					<SidePanelComponent open={this.state.openPanel} body={this.panelBody} title={this.panelTitle}/>
				</Main>
				</>
			:
			null}
			</div>
		);
	}
}